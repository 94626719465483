import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35b35c27"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "submit-btn",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_canjiandanwei = _resolveComponent("canjiandanwei")!
  const _component_fuzeren = _resolveComponent("fuzeren")!
  const _component_quyutype = _resolveComponent("quyutype")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createVNode(_component_van_form, {
      "show-error-message": false,
      "show-error": true,
      ref: "addArea",
      "validate-trigger": "onSubmit"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, {
          modelValue: _ctx.form.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
          modelModifiers: { trim: true },
          rules: [{ required: true, message: '区域名称必填' }],
          required: "",
          label: "区域名称",
          placeholder: "请输入区域名称",
          "input-align": "right"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.companyName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyName) = $event)),
          "is-link": "",
          label: "参建单位",
          placeholder: "请选择参建单位",
          "input-align": "right",
          readonly: "",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fShowCanjianPopover('canjiandanwei')))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.changePerson,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.changePerson) = $event)),
          "is-link": "",
          label: "负责人",
          placeholder: "请选择负责人",
          "input-align": "right",
          readonly: "",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fShowCanjianPopover('fuzeren')))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.areaType,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.areaType) = $event)),
          "is-link": "",
          label: "区域类型",
          placeholder: "请选择区域类型",
          "input-align": "right",
          readonly: "",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fShowCanjianPopover('quyutype')))
        }, null, 8, ["modelValue"]),
        (_ctx.isTrue)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "submit-btn",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.fSumbit && _ctx.fSumbit(...args)))
            }, "提交"))
          : _createCommentVNode("", true),
        (!_ctx.isTrue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "提交"))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createVNode(_component_canjiandanwei, { ref: "canjiandanwei" }, null, 512),
    _createVNode(_component_fuzeren, { ref: "fuzeren" }, null, 512),
    _createVNode(_component_quyutype, { ref: "quyutype" }, null, 512)
  ], 64))
}